import classNames from 'classnames';
import React from 'react';
import Link from 'next/link';
import { CumulusTvShowComponent } from '@src/interfaces';
import ImageComponent from '@src/components/Image/ImageComponent';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import styles from './TvShow.module.scss';

const TvShow = ({ title, description, image, url }: CumulusTvShowComponent) => {
  const container = (
    <div className={styles.container}>
      {image ? <ImageComponent image={image} size="large_uncropped" /> : null}

      {(title || description) ? <div className={styles.inner}>
        {title ? <h2>{title}</h2> : null}

        {description ? <div
          className={classNames('teaser-text', styles.description)}
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: description }}
            /> : null}
      </div> : null}
    </div>
  );

  return url ? (
    <Link as={stripUrlPlaceholder(url)} href="/">
      <a className={styles.showLink} data-foo="bar" title={title}>
        {container}
      </a>
    </Link>
  ) : (
    container
  );
};

export default TvShow;
